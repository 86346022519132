import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ClassNames from 'classnames';
import FullStory, { FullStoryAPI } from 'react-fullstory'

import RequestsView from '../../pages/Requests'
import InProgressView from '../../pages/InProgress'
import CompletedView from '../../pages/Completed'
import NewCardView from '../../pages/NewCard'
import CardDetailsView from '../../pages/CardDetails'
import WidgetButton from '../WidgetButton'
import ModalRenderer from '../../components/ModalRenderer'

const ORG_ID = 'M1E87'

const SIZES = {
  'icon': {
    height: '64px',
    width: '40px'
  },
  'icon-with-text': {
    height: '130px',
    width: '46px'
  },
  'text': {
    height: '95px',
    width: '46px'
  }
}

function bindEvent(element, eventName, eventHandler) {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent('on' + eventName, eventHandler);
  }
}

export default class AcuteUI extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: props.widgetOpened,
      isloaded: false
    }

    this.onToggleOpen = this.onToggleOpen.bind(this)
  }

  componentDidMount() {
    const { history, cardId, ssoUser, user, token, ssoLoginUser, logOut, getOrg, 
      getWidgetSettings, pingWidget, preview } = this.props

    let message = { action: 'act_widget_loaded' }

    getOrg()
    getWidgetSettings({
      cb: (err, res = {}) => {
        const { data = {} } = res

        let message = { action: 'act_widget_loaded' }

        if (data) {
          const { buttonBaseColor, buttonSecondaryColor, buttonIcon, buttonType,
            buttonText, nonPublic, installed, whiteLabel } = data

          this.setState({
            baseColor: buttonBaseColor,
            darkColor: buttonSecondaryColor || buttonBaseColor,
            icon: buttonIcon,
            type: buttonType,
            text: buttonText,
            nonPublic: nonPublic,
            whiteLabel: whiteLabel
          })

          message["data"] = {
            type: buttonType,
            sizes: SIZES[buttonType]
          }

          if (!preview && !installed) {
            pingWidget()
          }
        }

        window.parent.postMessage(message, '*');
      }
    })

    // Listen to message from parent window
    bindEvent(window, 'message', function (e) {
      var data = {}
      data = e.data

      if (data.action === 'set_act_storage') {
        const actionData = data.data || {}
        const actionDataUser = actionData.user || {}
        const storage = localStorage.getItem('act_storage')

        if (actionData.widget) {
          const { color } = actionData.widget

          if (color) {
            this.setState({
              baseColor: color.base,
              darkColor: color.dark
            })
          }
        }
        //TODO see if this is still needed
        if (storage) {
          const storageData = JSON.parse(storage) || {}
          const storageUser = storageData.user || {}

          // if (storageUser.id != actionDataUser.id) {
          //   console.log('different user')
          //   logOut()
          //   // should I empty local storage?
          //   //localStorage.removeItem('act_storage')
          // }

          // if (actionDataUser.id != user.id) {
          //   console.log('different user')
          //   logOut()
          // }

        }
        
        // check is new data matches user
        if (actionDataUser.id != user.id) {
          logOut()
        }

        if (actionDataUser.email) {
          ssoLoginUser({ data: actionDataUser })
        }

        localStorage.setItem('act_storage', JSON.stringify(data.data))
        
        this.setState({
          isloaded: true
        })
      } else if (data.action === 'open_act_widget') {
        this.onToggleOpen()
      } else if (data.action === 'reload_act_widget') {
        const actionData = data.data || {}
        const { buttonBaseColor, buttonSecondaryColor, buttonIcon, buttonType,
          buttonText, nonPublic } = actionData
        const { history } = this.props

        this.setState({
          baseColor: buttonBaseColor,
          darkColor: buttonSecondaryColor || buttonBaseColor,
          icon: buttonIcon,
          type: buttonType,
          text: buttonText,
          nonPublic: nonPublic
        })

        const message = {
          action: 'resize_frame',
          data: {
            open: this.state.isOpen,
            type: buttonType,
            sizes: SIZES[buttonType]
          }
        }

        window.parent.postMessage(message, '*');
        
        if (this.state.isOpen && nonPublic) {
          history.push('/new')
        } else if (this.state.isOpen && !nonPublic) {
          history.push('/')
        }
      }
    }.bind(this))

    if (cardId) history.push(`/card/${cardId}`)
  }

  onToggleOpen () {
    const { nonPublic } = this.state
    const { history, logOut } = this.props

    this.setState({isOpen: !this.state.isOpen }, () => {
      const { type } = this.state

      const message = {
        action: 'resize_frame',
        data: {
          open: this.state.isOpen,
          type: type,
          sizes: SIZES[type]
        }
      }

      window.parent.postMessage(message, '*');
    })

    if (nonPublic) {
      history.push('/new')
    }
    else {
      history.push('/')
    }
  }

  identifyUser = () => {
    const { user = {} } = this.props
    const { id = '', fullName = '',
      email = '' } = user

    // FullStoryAPI('identify', id, {
    //   displayName: fullName,
    //   email: email,
    // })
  }

  fullStory = () => {    
    return (
      process.env.NODE_ENV === 'production' ?
        <>
          {/* <FullStory
            org={ORG_ID}
            debug={false}
            host="fullstory.com"
            namespace="FS"
            isOuterScript={true}
            runInIframe={true}
          /> */}
          { this.identifyUser() }
        </>
      : null
    )
  }

  render () {
    const { isOpen, baseColor, darkColor, isloaded, icon, type, text, 
      nonPublic, whiteLabel } = this.state
    const { height, cardId } = this.props
    const widgetClassnames = ClassNames({
      'aui-widget-container': true,
      'widget-with-text': (type === 'icon-with-text' || type === 'text'),
      'aui-widget-container--active': isOpen
    });

    if (!isloaded) {
      return <></>
    }

    if (!isOpen) {
      return (
        <WidgetButton
          open={isOpen}
          onClick={this.onToggleOpen}
          baseColor={baseColor}
          darkColor={darkColor}
          icon={icon}
          type={type}
          text={text}
        />
      )
    }

    return (
      <div className={widgetClassnames} style={{
        //height: height,
      }}>
        { this.fullStory() }
        <WidgetButton
          open={isOpen}
          onClick={this.onToggleOpen}
          baseColor={baseColor}
          darkColor={darkColor}
          icon={icon}
          type={type}
          text={text}
        />

        <div className="aui-wrapper">
          <ModalRenderer darkColor={darkColor} />
          <Switch>
            <Route 
              exact 
              path='/' 
              render={(props) => <RequestsView {...props} baseColor={baseColor} darkColor={darkColor} whiteLabel={whiteLabel} />}
            />
            <Route 
              path='/wip'
              render={(props) => <InProgressView {...props} baseColor={baseColor} darkColor={darkColor} whiteLabel={whiteLabel} />}
            />
            <Route 
              path='/completed'
              render={(props) => <CompletedView {...props} baseColor={baseColor} darkColor={darkColor} whiteLabel={whiteLabel} />}
            />

            <Route 
              path='/new' 
              render={(props) => <NewCardView {...props} baseColor={baseColor} darkColor={darkColor} nonPublic={nonPublic} whiteLabel={whiteLabel} />}
            />
            <Route 
              path='/card/:id' 
              render={(props) => <CardDetailsView {...props} baseColor={baseColor} darkColor={darkColor} whiteLabel={whiteLabel} />}
            />
          </Switch>
        </div>
      </div>
    )
  }
}
