import React from 'react'
import { Provider } from 'react-redux'
import { routerReducer } from 'react-router-redux'

import { sagas, configureStore } from 'acute-uilib'

export default function(orgData) {
  const config = {
    development: process.env.NODE_ENV === 'production' ? false : true,
    sagas
  }

  const optionalReducers = { router: routerReducer }
  const { store } = configureStore(
    config,
    optionalReducers,
    null,
    orgData
  )

  class StoreWrapper extends React.PureComponent {
    render () {
      return (
        <Provider store={store}>
          {this.props.children}
        </Provider>
      )
    }
  }

  return StoreWrapper
}
