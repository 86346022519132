import React from 'react'
import { constants as Constants } from 'acute-uilib'
import SignupForm from './components/SignupForm'
import LoginForm from './components/LoginForm'
import ForgotPassword from './components/ForgotPassword'

import './style.css'

export default class ModalRenderer extends React.PureComponent {
  handleClick () {
    const { hideModal } = this.props

    hideModal()
  }

  renderContent() {
    const { modalState, darkColor } = this.props
    const { modal: { formState = '' } } = Constants

    switch (modalState.formState) {
      case formState.SIGNUP: {
        return <SignupForm darkColor={darkColor} />
      }

      case formState.LOGIN: {
        return <LoginForm darkColor={darkColor} />
      }

      case formState.FORGOT_PASSWORD: {
        return  <ForgotPassword />
      }

      default: {
        return <SignupForm darkColor={darkColor} />
      }
    }
  }

  render () {
    const { modalState } = this.props;

    return (
      modalState.active ?
        <div className='aui-modal'>
          <div className='aui-modal__overlay' onClick={() => this.handleClick()} />
          <div className='aui-modal__content'>
            { this.renderContent() }
          </div>
        </div>
      : null
    )
  }
}
