import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@blueprintjs/core'

import './style.css'

export default class BackBar extends React.PureComponent {

  handleClick = () => {
    const { history } = this.props
    const { location = {} } = history
    const { state = {} } = location

    state.backRoute ? history.push(state.backRoute ) : history.goBack()
  }

  render () {
    const { label, baseColor, darkColor, modal, closeModal, nonPublic } = this.props

    let style = {}
    if (baseColor || darkColor) {
      style = {
        background: `linear-gradient(-60deg,${darkColor},${baseColor})`
      }
    }

    return (
      <div className='aui-backbar' style={style}>
        {
          nonPublic ? null
        : <div className='aui-backbar-button' onClick={this.handleClick}>
            <Icon
              icon='arrow-left'
              iconSize={Icon.SIZE_LARGE}
            />
            {label && <h3>{label}</h3>}
          </div>
        }
        {
          modal && 
            <span class="aui-header-close" onClick={closeModal}>
              <Icon
                icon='cross'
                class="aui-header-close"
                iconSize={Icon.SIZE_LARGE}
              />
            </span>
        }
      </div>
    )
  }
}
