import { connect } from 'react-redux'
import Requests from './Requests'
import { actions as Actions } from 'acute-uilib'
import { constants as Constants } from 'acute-uilib'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data = {} } = state
  const { cards = [], votes = [], loading = {}, organisation = {} } = data
  const filteredCards = Object.values(cards)
  // .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))

  return {
    cards: filteredCards,
    votes: votes,
    loading: loading.isFetching,
    organisation
  }
}

const mapDispatchToProps = dispatch => ({
  loadCards: () => {
    const { card: { States = {} } } = Constants

    dispatch(Actions.card.getByState(States.REQUESTED))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Requests)
