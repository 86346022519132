'use strict'

import _isEmpty from 'lodash/isEmpty'

export default (cards, searchValue) => {
  return _isEmpty(searchValue)
    ? cards
    : cards.filter(card => {
      const sv = searchValue.toLowerCase()

      return (
        card.title.toLowerCase().match(sv) ||
        card.body.toLowerCase().match(sv)
      )
    })
}
