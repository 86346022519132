import React from 'react'
import ReactDOM from 'react-dom'
import { ConnectedRouter } from 'react-router-redux'
import { createBrowserHistory } from 'history'

import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import 'emoji-mart/css/emoji-mart.css'
import './index.css'

import Constants from './constants';
import AcuteUI from './components/Acute'
import AcuteModalUI from './components/AcuteModal'
import genStoreWrapper from './StoreWrapper'

const history = createBrowserHistory()

const params = new URLSearchParams(history.location.search)
const token = params.get('token')
const query = params.get('fr')
const id = params.get('id')
const modal = params.get('modal')
const open = params.get('open')
const preview = params.get('preview')

const config = {
  organisation: {
    apiToken: token
  }
}

const StoreWrapper = genStoreWrapper(config)

ReactDOM.render(
  token ? (
    <StoreWrapper>
      <ConnectedRouter history={history}>
        { modal ?
          <AcuteModalUI
            widgetOpened={open ? true : false}
            preview={preview}
            // cardId={id}
          />
          : <AcuteUI
            widgetOpened={open ? true : false}
            preview={preview}
            // cardId={id}
          />
        }
      </ConnectedRouter>
    </StoreWrapper> )
  : null,
document.getElementById('acute-ui'))
