import React from 'react'
import { parseToRgb } from 'polished'
import { Icon } from '@blueprintjs/core'

import './style.css'

export default class WidgetButton extends React.PureComponent {
  render () {
    const { open, onClick, baseColor, darkColor, icon, type, text } = this.props
    // const acuteIcon = <img src='/images/acute_icon.png' alt='Open Acute Widget' />
    let textColor = '#fff'

    // TODO: do this sometimes
    // if (baseColor || darkColor) {
    //   const rgb = parseToRgb(baseColor || darkColor)
    //   const { red, green, blue } = rgb
    //   if ((red * 0.299 + green * 0.587 + blue * 0.114) > 150) {
    //     textColor = '#000'
    //   }
    // }

    const acuteIcon = <span key={icon ? icon: 'fas fa-lightbulb'}>
      <i 
        className={icon ? icon: 'fas fa-lightbulb'}
        style={{color: textColor}}
      ></i>
    </span>
    const closeIcon = <Icon icon='cross' style={{fill: textColor}} />
    const activeIcon = open ? closeIcon : acuteIcon
    
    let style = {}
    if (baseColor || darkColor) {
      style = {
        backgroundImage: `linear-gradient(-134deg,${darkColor},${baseColor})`
      }
    }

    let buttonClassname = 'aui-widget-button-wrapper'
    if (type === 'icon-with-text' || type === 'text') {
      buttonClassname = 'aui-widget-button-wrapper-with-text'
    } 
    if ( type === 'text') {
      buttonClassname += ' icon-hidden'
    }
    if (open) {
      buttonClassname += ' is-open'
    }

    return (
      <div
        className={buttonClassname}
        onClick={onClick}
        style={style}
      >
        {text ? 
          <p class={'aui-widget-button-label'} style={{color: textColor}}>
            {text}
          </p>
        : null}
        {activeIcon}
      </div>
    )
  }
}
