import React from 'react'
import { CellMeasurer } from 'react-virtualized'

import Card from '../Card'

const renderRow = (cache = null, cards = [], votes = [], darkColor, data = {}) => {
  const { index, key, style, parent } = data
  const card = { ...cards[index], ...votes[cards[index].id] }
  const content = (
    <span>
      <Card
        key={card.id}
        data={card}
        style={{
          marginBottom: index === cards.length - 1 ? '10px' : '0',
        }}
        darkColor={darkColor}
      />
    </span>
  )

  if (cache) {
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={card.id}
        parent={parent}
        rowIndex={index}
      >
        {({ measure }) => (
          <div style={style}>
            {typeof content === 'function' ? content(measure) : content}
          </div>
        )}
      </CellMeasurer>
    )
  }

  return (
    <div key={key} style={style}>
      {typeof content === 'function' ? content() : content}
    </div>
  )
}

export { renderRow }
