import React from 'react'
import ClassNames from 'classnames'
import { TextArea, Button } from '@blueprintjs/core'
import { Emoji, Picker } from 'emoji-mart'
import './style.css'

export default class WriteBox extends React.Component {
  state = {
    value: '',
    emojiDrawerOpen: false
  }

  constructor (props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.onTextChange = this.onTextChange.bind(this)
    this.onToggleEmojiDrawer = this.onToggleEmojiDrawer.bind(this)
    this.onSelectEmoji = this.onSelectEmoji.bind(this)
    this.closeEmojiDrawer = this.closeEmojiDrawer.bind(this)
  }

  onSubmit () {
    const { onSubmit } = this.props
    const { value } = this.state

    this.closeEmojiDrawer()
    onSubmit({ value, cb: (err, res = {}) => {
      if (err) {
        //TODO
      } else {
        this.setState(() => ({ value: '' }))
      }
    }})
  }

  onTextChange (e) {
    const { value } = e.target

    this.setState(() => ({ value }))
  }

  onToggleEmojiDrawer () {
    this.setState((state) => ({
      emojiDrawerOpen: !state.emojiDrawerOpen,
    }))
  }

  closeEmojiDrawer () {
    this.setState({ emojiDrawerOpen: false })
  }

  onSelectEmoji (emoji = {}) {
    const { colons } = emoji

    this.setState(({ value }) => ({
      value: value + colons
    }))
  }

  render () {
    const { value, emojiDrawerOpen } = this.state
    const { darkColor } = this.props

    return (
      <div className='aui-write-box'>
        <div className='aui-write-box__input_wrapper'>
          <TextArea
            value={value}
            onClick={this.closeEmojiDrawer}
            onChange={this.onTextChange}
            placeholder="Leave a comment"
          />

          <Button
            text='Submit'
            onClick={this.onSubmit}
            style={{background: darkColor}}
          />
        </div>
      </div>
    )
  }
}
