import styled from 'styled-components'
import { transparentize } from 'polished'

export const Button = styled.div`
  background: #fff;
  border: 1px solid ${props => props.darkColor ? 
  props.darkColor : '#3023ae'};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  min-width: 170px;

  font-weight: 600;
  line-height: 1;
  color: ${props => props.darkColor ?
    props.darkColor : '#3023ae'};

  padding: 6px 12px;
  text-align: center;

  .aui-write-button__icon {
    fill: ${props => props.darkColor ?
    props.darkColor : '#3023ae'};

    width: 14px;
    margin-right: 6px;
  }

  &:hover {
    cursor: pointer;
    ${props => props.darkColor ? 
      `box-shadow: 0 0 0 2px ${transparentize(0.7, props.darkColor)}`
    : null}
  }
`