import React from 'react'
import ClassNames from 'classnames'
import { Icon } from '@blueprintjs/core'
import CommentIcon from './assets/icons/card_comment.svg'
import EO from 'emojione'
import Autolink from '../../util/Autolink'

import Tags from '../Tags'
import './style.css'
import { Link } from 'react-router-dom'
import { VoteButton } from './style'

export default class Card extends React.Component {
  componentDidMount () {
    const { data, loadAllVotes } = this.props

    loadAllVotes(data.id)
  }

  componentWillReceiveProps (nextProps) {
    const { user, loadAllVotes } = nextProps
    const { data } = this.props

    if (user !== this.props.user) {
      loadAllVotes(data.id)
    }
  }

  handleClick = () => {
    const { history, data = {} } = this.props

    history.push(`/card/${data.id}`)
  }

  upvote = () => {
    const { data = {}, submitUpvote, showModal, ssoUser, token } = this.props

    const storage = localStorage.getItem('act_storage')
    const storageData = JSON.parse(storage) || {}
    const { user } = storageData

    if (token) {
      submitUpvote(data.id)
    } else if (!token && user) {
      ssoUser({
        data: user,
        userAction: submitUpvote.bind(null, data.id),
        cb: (err, res = {}) => {
          if (err) {
            const { response = {} } = err
            const { data = {} } = response

            console.log(`SSO failed with error ${data.error}.`)
            showModal(submitUpvote.bind(null, data.id))
          }
        }
      })
    } else {
      showModal(submitUpvote.bind(null, data.id))
    }
  }

  downvote = () => {
    const { data = {}, submitDownvote, showModal, ssoUser, token } = this.props

    const storage = localStorage.getItem('act_storage')
    const storageData = JSON.parse(storage) || {}
    const { user } = storageData

    if (token) {
      submitDownvote(data.id)
    } else if (!token && user) {
      ssoUser({
        data: user,
        userAction: submitDownvote.bind(null, data.id),
        cb: (err, res = {}) => {
          if (err) {
            const { response = {} } = err
            const { data = {} } = response

            console.log(`SSO failed with error ${data.error}.`)
            showModal(submitDownvote.bind(null, data.id))
          }
        }
      })
    } else {
      showModal(submitDownvote.bind(null, data.id))
    }
  }

  handleSubmit = (e) => {
    const { data = {} } = this.props
    e.stopPropagation()

    data.has_voted ? this.downvote() : this.upvote()
  }

  render () {
    const { data = {}, style, darkColor } = this.props
    const body = data.body.length < 100 ? data.body : (data.body.substring(0, 97) + '...')

    return (
      <div className='aui-card' style={style} onClick={this.handleClick}>
        <div className='aui-card__content'>
          <div className='aui-card__content_title'>
            <p dangerouslySetInnerHTML={{
              __html: EO.shortnameToImage(data.title)
            }}/>

            <VoteButton active={data.has_voted}
              onClick={this.handleSubmit}
              darkColor={darkColor}
            >
              <Icon icon='chevron-up' className="upvote" />
              <span>{data.votes || 0}</span>
            </VoteButton>

            <Link to={`/card/${data.id}`}>
              <div className="aui-card__view-comments-button">
                <img src={CommentIcon} width="16px" alt="" />

                <span className="aui-card__total-comments">
                { data.comments }
                </span>
              </div>
            </Link>
          </div>

          <p>
            <Autolink text={body} />
          </p>

          { data.state === 'planned' &&
            <div className='aui-card__tags-state'>
              <span className='aui-card-state'>Planned</span>
            </div>
          }
          { data.state === 'ur' &&
            <div className='aui-card__tags-state-ur'>
              <span className='aui-card-state-ur'>Under Review</span>
            </div>
          }
        </div>
      </div>
    )
  }
}
