import React from 'react'
import { Icon } from '@blueprintjs/core'

import './style.css'
import { Button } from './style'
import { Link } from 'react-router-dom'

export default class WriteButton extends React.PureComponent {
  render () {
    const { onClick, label, darkColor } = this.props

    let buttonStyle = {}
    let iconStyle = {}

    if (darkColor) {
      buttonStyle = {
        color: darkColor,
        borderColor: darkColor,
        
        "&:hover": {
          boxShadow: `0 0 0 0.5px ${darkColor}`
        },
      }
      iconStyle = {
        fill: darkColor
      }
    }

    return (
      <Button
        className='aui-button aui-write-button'
        onClick={onClick}
        darkColor={darkColor}
      >
        <Icon className='aui-write-button__icon' icon='plus' style={iconStyle} />
        <span>{label || 'Make a suggestion'}</span>
      </Button>
    )
  }
}

export class WriteButtonWithLink extends React.PureComponent {
  render () {
    const { label, darkColor } = this.props

    return (
      <div className='aui-card-list__main-action'>
        <Link to={this.props.to}>
          <WriteButton onClick={this.props.onClick} label={label} darkColor={darkColor}/>
        </Link>
      </div>
    )
  }
}

export class FeedbackButton extends React.PureComponent {
  render() {
    return (
      <Link to={this.props.to}>
        <div
          className='aui-button aui-write-button'
          onClick={this.props.onClick}
          style={{
            color: 'white',
            borderColor: 'white',
            background: 'transparent'}}>
          <span>+ Make a suggestion</span>
        </div>
      </Link>
    )
  }
}
