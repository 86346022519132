import React from 'react'
import EO from 'emojione'
import ClassNames from 'classnames'
import { Icon } from '@blueprintjs/core'
import { ReactComponent as Trash } from '../../assets/trash.svg'
import timeDifference from '../../util/timeDifference'
import Autolink from '../../util/Autolink'

import './style.css'

export default class Comment extends React.Component {
  handleClick = () => {
    const { data = {}, deleteComment } = this.props

    deleteComment(data.id)
  }

  render () {
    const { data = {}, user = {}, style = {} } = this.props
    const { author = {} } = data

    return (
      <div className='aui-comment' style={style}>
        <div className='aui-comment__body'>
          <p style={{whiteSpace: 'pre-wrap'}}>
            <Autolink text={data.body} />
          </p>
        </div>

        <div className='aui-comment__author'>
          <div className='aui-comment__author__img'>
            <img src={author.avatarURL} alt="" />
            
            {author.admin &&
              <Icon icon='endorsed' className="admin-badge" />
            }
          </div>

          <div className='aui-comment__author__details'>
            <p className={ClassNames('aui-comment-author', {
              admin: author.admin
            })}>
              {author.fullName}
            </p>
            <p>{timeDifference(new Date, new Date(data.createdAt))}</p>
          </div>

          { author.id === user.id ?
            <div className="aui-card-details__delete_button" onClick={this.handleClick}>
              <Trash width="16px" height="16px" alt="" />
            </div>
          : null }
        </div>
      </div>
    )
  }
}
