import { connect } from 'react-redux'
import { actions as Actions } from 'acute-uilib'

import LoginForm from './LoginForm'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data } = state
  const { modalState = {} } = data

  return {
    userAction: modalState.userAction
  }
}

const mapDispatchToProps = dispatch => ({
  changeModalState: (formState) => {
    dispatch(Actions.modal.changeState(formState))
  },

  login: ({email, password, cb = {}}) => {
    dispatch(Actions.user.login({email, password, cb}))
  },

  hideModal: () => {
    dispatch(Actions.modal.hide())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
