import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { actions as Actions } from 'acute-uilib'
import NewCardView from './NewCard'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data = {} } = state
  const { user = {}, token = null, organisation = {} } = data

  return {
    user,
    token,
    organisation
  }
}

const mapDispatchToProps = dispatch => ({
  submitCard: ({ cb, title, body, tags } = {}) => {
    dispatch(Actions.card.submit({ cb, title, body, tags }))
  },

  showModal: (userAction) => {
    dispatch(Actions.modal.show(userAction))
  },

  ssoUser: ({ data, userAction, cb } = {}) => {
    dispatch(Actions.userAction.set(userAction))
    dispatch(Actions.user.sso({ data, cb }))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewCardView))
