import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ClassNames from 'classnames';
import FullStory, { FullStoryAPI } from 'react-fullstory'

import RequestsView from '../../pages/Requests'
import InProgressView from '../../pages/InProgress'
import CompletedView from '../../pages/Completed'
import NewCardView from '../../pages/NewCard'
import CardDetailsView from '../../pages/CardDetails'
import WidgetButton from '../WidgetButton'
import ModalRenderer from '../../components/ModalRenderer'

const ORG_ID = 'M1E87'

function bindEvent(element, eventName, eventHandler) {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent('on' + eventName, eventHandler);
  }
}

export default class AcuteModalUI extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: props.widgetOpened,
      isloaded: false,
      settingsLoaded: false
    }

    this.onToggleOpen = this.onToggleOpen.bind(this)
  }

  componentDidMount() {
    const { history, cardId, ssoUser, user, token, ssoLoginUser, logOut, 
      getOrg, getWidgetSettings, pingWidget, preview } = this.props

    const message = { action: 'act_widget_loaded' }

    window.parent.postMessage(message, '*');

    getOrg()
    getWidgetSettings({
      cb: (err, res = {}) => {
        const { data = {} } = res
        const { history } = this.props

        let message = { action: 'act_widget_loaded' }

        if (data) {
          const { modalBaseColor, modalSecondaryColor, modalNonPublic,
            installed, whiteLabel } = data

          this.setState({
            baseColor: modalBaseColor,
            darkColor: modalSecondaryColor || modalBaseColor,
            nonPublic: modalNonPublic,
            whiteLabel: whiteLabel
          })

          if (modalNonPublic) {
            history.push('/new')
          } else {
            history.push('/')
          }

          if (!preview && !installed) {
            pingWidget()
          }
        }

        window.parent.postMessage(message, '*');

        this.setState({
          settingsLoaded: true
        })
      }
    })

    // Listen to message from parent window
    bindEvent(window, 'message', function (e) {
      var data = {}
      data = e.data

      if (data.action === 'set_act_storage') {
        const actionData = data.data || {}
        const actionDataUser = actionData.user || {}
        const storage = localStorage.getItem('act_storage')

        if (actionData.widget) {
          const { color } = actionData.widget

          if (color) {
            this.setState({
              baseColor: color.base,
              darkColor: color.dark
            })
          }
        }
        //TODO see if this is still needed
        if (storage) {
          const storageData = JSON.parse(storage) || {}
          const storageUser = storageData.user || {}

          // if (storageUser.id != actionDataUser.id) {
          //   console.log('different user')
          //   logOut()
          //   // should I empty local storage?
          //   //localStorage.removeItem('act_storage')
          // }

          // if (actionDataUser.id != user.id) {
          //   console.log('different user')
          //   logOut()
          // }

        }
        
        // check is new data matches user
        if (actionDataUser.id != user.id) {
          logOut()
        }

        if (actionDataUser.email) {
          ssoLoginUser({ data: actionDataUser })
        }

        localStorage.setItem('act_storage', JSON.stringify(data.data))
        
        this.setState({
          isloaded: true
        })
      } else if (data.action === 'open_act_modal') {
        this.onToggleOpen()
      } else if (data.action === 'reload_act_modal') {
        const actionData = data.data || {}
        const { modalBaseColor, modalSecondaryColor, modalNonPublic } = actionData
        const { history } = this.props

        this.setState({
          baseColor: modalBaseColor,
          darkColor: modalSecondaryColor || modalBaseColor,
          nonPublic: modalNonPublic
        })

        if (this.state.isOpen && modalNonPublic) {
          history.push('/new')
        }
      }
    }.bind(this))

    if (cardId) history.push(`/card/${cardId}`)
  }

  onToggleOpen () {
    const { history, logOut } = this.props

    this.setState({isOpen: !this.state.isOpen }, () => {
      const message = {
        action: 'resize_modal',
        data: this.state.isOpen
      }

      window.parent.postMessage(message, '*');
    })

    history.push('/')
  }

  onCloseModal = () => {
    const { history, logOut } = this.props

    this.setState({ isOpen: false }, () => {
      const message = {
        action: 'resize_modal',
        data: this.state.isOpen
      }

      window.parent.postMessage(message, '*');
    })

    history.push('/')
  }

  identifyUser = () => {
    const { user = {} } = this.props
    const { id = '', fullName = '',
      email = '' } = user

    // FullStoryAPI('identify', id, {
    //   displayName: fullName,
    //   email: email,
    // })
  }

  fullStory = () => {    
    return (
      process.env.NODE_ENV === 'production' ?
        <>
          {/* <FullStory
            org={ORG_ID}
            debug={false}
            host="fullstory.com"
            namespace="FS"
            isOuterScript={true}
            runInIframe={true}
          /> */}
          { this.identifyUser() }
        </>
      : null
    )
  }

  render () {
    const { isOpen, baseColor, darkColor, isloaded, settingsLoaded, 
      nonPublic, whiteLabel } = this.state
    const { height, cardId } = this.props
    const widgetClassnames = ClassNames({
      'aui-modal-container': true,
      'aui-modal-container--active': true //isOpen
    });

    if (!isloaded || !settingsLoaded) {
      return <></>
    }

    if (!isOpen) {
      return <></>
    }

    return (
      <div className={widgetClassnames} style={{
        //height: height,
      }}>
        { this.fullStory() }
        <div className="aui-wrapper">
          <ModalRenderer darkColor={darkColor} />
          <Switch>
            <Route 
              exact 
              path='/' 
              render={(props) => 
              <RequestsView {...props} baseColor={baseColor} darkColor={darkColor} modal={true} closeModal={this.onCloseModal} whiteLabel={whiteLabel} />
              }
            />
            <Route 
              path='/wip'
              render={(props) => 
                <InProgressView {...props} baseColor={baseColor} darkColor={darkColor} modal={true} closeModal={this.onCloseModal} whiteLabel={whiteLabel} />
            }
            />
            <Route 
              path='/completed'
              render={(props) => <CompletedView {...props} baseColor={baseColor} darkColor={darkColor} modal={true} closeModal={this.onCloseModal} whiteLabel={whiteLabel} />}
            />

            <Route 
              path='/new' 
              render={(props) => <NewCardView {...props} baseColor={baseColor} darkColor={darkColor} modal={true} nonPublic={nonPublic}
                closeModal={this.onCloseModal} />}
            />
            <Route 
              path='/card/:id' 
              render={(props) => <CardDetailsView {...props} baseColor={baseColor} darkColor={darkColor} modal={true} closeModal={this.onCloseModal} />}
            />
          </Switch>
        </div>
      </div>
    )
  }
}
