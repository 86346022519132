import React from 'react'
import { NonIdealState, Spinner } from '@blueprintjs/core'

import filterCards from '../../util/filterCards'
import CardList from '../../components/CardList'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import './style.css'
import { WriteButtonWithLink } from '../../components/WriteButton'

export default class RequestsView extends React.Component {
  state = {
    searchValue: ''
  }

  constructor (props) {
    super(props)

    this.onSearchChange = this.onSearchChange.bind(this)
  }

  componentDidMount () {
    const { loadCards } = this.props

    loadCards()
  }

  onSearchChange (e) {
    const { value } = e.target
    this.setState(() => ({ searchValue: value }))
  }

  render () {
    const { searchValue } = this.state
    const { cards = [], votes = [], loading = false, baseColor, darkColor, 
      modal, closeModal, whiteLabel, organisation }  = this.props
    const filteredCards = filterCards(cards, searchValue)
    const orgName = organisation.name

    return (
      <div className='aui-wrapper__requests'>
        <Header
          searchValue={searchValue}
          onSearchChange={this.onSearchChange}
          baseColor={baseColor}
          darkColor={darkColor}
          modal={modal}
          closeModal={closeModal}
        />

        <CardList
          cards={filteredCards}
          votes={votes}
          emptyState={(
            <NonIdealState
              title='No Feedback'
            />
          )}
          loading={loading}
          darkColor={darkColor}
        />

        <WriteButtonWithLink to='/new' label="Make a suggestion" darkColor={darkColor} />

        <Footer whiteLabel={whiteLabel} orgName={orgName}/>
      </div>
    )
  }
}
