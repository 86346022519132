export default [{
  type: 'text',
  placeholder: 'Full Name',
  key: 'signupFullName',
  name: 'fullName'
}, {
  type: 'email',
  placeholder: 'Email',
  key: 'signupEmail',
  name: 'email'
}, {
  type: 'password',
  placeholder: 'Password',
  key: 'signupPassword',
  name: 'password'
}]
