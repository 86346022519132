export default [{
  type: 'email',
  placeholder: 'Email',
  key: 'loginEmail',
  name: 'email'
}, {
  type: 'password',
  placeholder: 'Password',
  key: 'loginPassword',
  name: 'password'
}]
