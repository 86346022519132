import React from 'react'
import { AutoSizer, List, CellMeasurerCache } from 'react-virtualized'
import Scrollbars from 'react-custom-scrollbars'
import { ScrollbarOffset } from './constants'

import { renderRow } from './helpers'
import './style.css'

export default class CommentList extends React.PureComponent {
  constructor (props) {
    super(props)

    this.cache = new CellMeasurerCache({
      defaultHeight: 250,
      fixedWidth: true,
      keyMapper: (index) => {
        const item = this.props.comments[index] || {}
        return item ? `${item.id}` : index
      },
    })
  }

  render () {
    const { emptyState, comments = [] } = this.props

    if (comments.length === 0 && emptyState) {
      return (
        <div className='aui-card-list'>
          {emptyState}
        </div>
      )
    }

    return (
      <div className='aui-comment-list'>
        <AutoSizer style={{ outline: 'none' }}>
          {({ height, width }) => (
            <Scrollbars
              autoHide
              style={{ height, width: width + ScrollbarOffset }}
            >
              <List
                deferredMeasurementCache={this.cache}
                rowRenderer={renderRow.bind(this, this.cache, comments)}
                width={width}
                height={height}
                rowHeight={this.cache.rowHeight}
                rowCount={comments.length}
                style={{
                  overflowX: false,
                  overflowY: false,
                }}
              />
            </Scrollbars>
          )}
        </AutoSizer>
      </div>
    )
  }
}
