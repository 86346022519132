import React from 'react'
import { CellMeasurer } from 'react-virtualized'

import Comment from '../Comment'

const renderRow = (cache = null, comments = [], data = {}) => {
  const { index, key, style, parent } = data
  const comment = comments[index]
  const content = (
    <Comment
      key={key}
      data={comment}
      style={{
        marginBottom: index === comments.length - 1 ? '10px' : '0',
      }}
    />
  )

  if (cache) {
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure }) => (
          <div style={style}>
            {typeof content === 'function' ? content(measure) : content}
          </div>
        )}
      </CellMeasurer>
    )
  }

  return (
    <div key={key} style={style}>
      {typeof content === 'function' ? content() : content}
    </div>
  )
}

export { renderRow }
