import React from 'react'

import './style.css'

export default class Tags extends React.PureComponent {
  render () {
    const { tags } = this.props

    return (
      <ul className='aui-tags-wrapper'>
        {tags.map(tag => (
          <li key={tag}>{tag}</li>
        ))}
      </ul>
    )
  }
}
 