import React from 'react'
import { constants as Constants } from 'acute-uilib'

import { renderFormFields } from '../helpers'
import formFields from './formFields'
import './style.css'

export default class SignupForm extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      error: null
    }
  }

  handleSubmit = (e) => {
    const { register, hideModal } = this.props
    const { email = '', fullName = '',  password = '' } = this.state

    e.preventDefault()

    register({
      email,
      fullName,
      password,
      cb: (err, res = {}) => {
        if (err) {
          const { response = {} } = err
          const { data = {} } = response

          this.setState({ error: data.error })
        } else {
          hideModal()
        }
      }
    })
  }

  handleClick = () => {
    const { modal: { formState = '' } } = Constants
    const { changeModalState } = this.props

    changeModalState(formState.LOGIN)
  }

  _handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render () {
    const { darkColor } = this.props
    const { error } = this.state

    return (
      <div className="aui-signupForm__container">
        <div className="aui-signupForm__content">
          <p className="aui-signupForm__title">Sign up</p>
          <p className="aui-signupForm__subtitle">
            Create an account so you can vote, comment,
            and remain up-to-date about features that interest you.
          </p>
          <form className="aui-signuForm__form">
            <div className="error__container">{ error }</div>

            { renderFormFields(formFields, this._handleChange) }

            <div className="buttons__container">
              <button 
                style={{background: darkColor}} 
                onClick={this.handleSubmit}
              >
                Sign Up
              </button>
            </div>
          </form>
        </div>
        <div className="aui-signupForm__footer">
          <span onClick={this.handleClick}>Already have an account? Log in</span>
        </div>
      </div>
    )
  }
}
