import React from 'react'
import ClassNames from 'classnames'
import { FeedbackButton } from '../WriteButton'
import { propTypes, defaultProps } from './props'
import './style.css'

export default class NavBar extends React.PureComponent {
  static propTypes = propTypes
  static defaultProps = defaultProps

  render () {
    const { links, activeLink, activePath, onSetActive, modal } = this.props

    const classNames = modal ? 'aui-navbar aui-modal-navbar' : 'aui-navbar'

    return (
      <ul className={classNames}>
        {links.map(link => (
          <li
            key={link.label}
            onClick={() => onSetActive(link)}
            className={ClassNames({
              active: link === activeLink || link.path === activePath
            })}
          >
            <button className="aui-navbar-button">
              {link.label}
            </button>
          </li>
        ))}
      </ul>
    )
  }
}
