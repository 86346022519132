import styled from 'styled-components'
import { transparentize } from 'polished'

export const VoteButton = styled.div`
  position: absolute;
  top: 0.9em;
  right: 10px;

  color: ${props => props.active ? 
    props.darkColor ? props.darkColor : '#3023ae' 
  : '#ccc'};
  padding: 0.1em 0.6em;
  display: flex;
  flex-direction: column;

  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid ${props => props.active ? 
    props.darkColor ? props.darkColor : '#3023ae' 
  : 'rgba(35,35,35,.2)'};

  transition: box-shadow .1s ease-in;

  &:focus {
    ${props => props.darkColor ?
      `box-shadow: 0 0 0 2px ${transparentize(0.7, props.darkColor)}`
    : `box-shadow: 0 0 0 2px rgba(48, 35, 174, .3);`}
  }

  &:active {
    ${props => props.darkColor ?
      `box-shadow: 0 0 0 2px ${transparentize(0.7, props.darkColor)}`
    : `box-shadow: 0 0 0 2px rgba(48, 35, 174, .3);`}  
  }

  &:hover {
    color: ${props => props.active ? 
      props.darkColor ? props.darkColor : '#3023ae' 
    : '#999'};
    ${props => props.darkColor ?
      `box-shadow: 0 0 0 2px ${transparentize(0.7, props.darkColor)}`
    : `box-shadow: 0 0 0 2px rgba(48, 35, 174, .3);`}
  }

  > * {
    display: block;
  }
`