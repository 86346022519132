import React from 'react'
import { Icon } from '@blueprintjs/core'
import ClassNames from 'classnames'

import './style.css'
import SearchBar from '../SearchBar'
import NavBar from '../NavBar'

const LINKS = {
  REQUESTS: {
    label: 'Open',
    path: '/',
  },

  IN_PROGRESS: {
    label: 'In-Progress',
    path: '/wip',
  },

  COMPLETED: {
    label: 'Completed',
    path: '/completed',
  }
}

export default class Header extends React.PureComponent {
  constructor (props) {
    super(props)

    this.onSetActiveLink = this.onSetActiveLink.bind(this)
  }

  onSetActiveLink (activeLink) {
    const { history, emptyCards } = this.props
    const { location = {} } = history

    if (location.pathname !== activeLink.path) {
      emptyCards()
      this.setState(() => ({ activeLink }))
      history.push(activeLink.path)
    }
  }

  render () {
    const { searchValue, onSearchChange, location, baseColor, darkColor, 
      modal, organisation, closeModal } = this.props

    let style = {}
    const orgName = organisation.name || null

    if (baseColor || darkColor) {
      style = {
        background: `linear-gradient(-60deg,${darkColor},${baseColor})`
      }
    }

    const headerClassnames = ClassNames({
      'aui__header': true,
      'aui__modal__header': modal
    });

    return (
      <div className={headerClassnames} style={style}>
        {
          modal &&
          <>
            <span class="aui-header-close" onClick={closeModal}>
              <Icon
                icon='cross'
                class="aui-header-close"
                iconSize={Icon.SIZE_LARGE}
              />
            </span>
            {orgName ? <h1 style={{ textAlign: 'center', fontSize: '1.2rem', marginBottom: '10px' }}>{orgName} Feedback</h1>
              : <h1 style={{ textAlign: 'center', fontSize: '1.2rem', marginBottom: '10px' }}>Feedback</h1>  }
            <div className="aui-title">
              <p className="aui-title__title">
                Let us know how we can improve. 
                <br />
                Vote on existing posts or make a new suggestion.
              </p>
            </div>
          </>
        }
        <NavBar
          activePath={location.pathname}
          onSetActive={this.onSetActiveLink}
          modal={modal}
          links={[
            LINKS.REQUESTS,
            LINKS.IN_PROGRESS,
            LINKS.COMPLETED
          ]}
        />

        <SearchBar
          value={searchValue}
          onChange={onSearchChange}
        />
      </div>
    )
  }
}
