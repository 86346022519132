import PropTypes from 'prop-types'

const propTypes = {
  onSetActive: PropTypes.func.isRequired,
  links: PropTypes.array.isRequired,
  activeLink: PropTypes.string.isRequired,
}

const defaultProps = {
  onSetActive: () => {},
  links: [],
  activeLink: '',
}

export { propTypes, defaultProps }
