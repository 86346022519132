import { connect } from 'react-redux'
import { actions as Actions } from 'acute-uilib'
import { constants as Constants } from 'acute-uilib'
import InProgress from './InProgress'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data = {} } = state
  const { cards = {}, votes = [], loading = {}, organisation = {} } = data
  const filteredCards = Object.values(cards)
  //  .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))

  return {
    cards: filteredCards,
    votes: votes,
    loading: loading.isFetching,
    organisation
  }
}

const mapDispatchToProps = dispatch => ({
  loadCards: () => {
    const { card: { States = {} } } = Constants

    dispatch(Actions.card.getByState(States.IN_PROGRESS))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(InProgress)
