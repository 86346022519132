import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { actions as Actions } from 'acute-uilib'
import Header from './Header'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data = {} } = state
  const { user = {}, token = null, organisation = {} } = data

  return {
    user,
    token,
    organisation
  }
}

const mapDispatchToProps = dispatch => ({
  emptyCards: () => {
    dispatch(Actions.card.empty())
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
