import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { actions as Actions } from 'acute-uilib'
import Comment from './Comment'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data = {} } = state
  const { user = {} } = data

  return {
    user
  }
}

const mapDispatchToProps = dispatch => ({
  deleteComment: (commentId) => {
    dispatch(Actions.comments.remove(commentId))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Comment))
