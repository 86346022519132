import { connect } from 'react-redux'
import { actions as Actions } from 'acute-uilib'
import { withRouter } from 'react-router-dom'

import Acute from './Acute'

const HEIGHTS = {
  '*': '41.5em',
  '/new': '41.5em',
}

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { router = {}, data = {} } = state
  const { user = {}, token = null } = data

  const height = HEIGHTS['*']

  return {
    height,
    user,
    token
  }
}

const mapDispatchToProps = dispatch => ({
  registerUser: (data = {}) => {
    dispatch(Actions.user.register(data))
  },
  ssoLoginUser: ({ data, cb } = {}) => {
    dispatch(Actions.user.ssoLogin({ data, cb }))
  },
  logOut: () => {
    dispatch(Actions.user.logOut())
  },
  getOrg: () => {
    dispatch(Actions.org.get())
  },
  getWidgetSettings: ({ cb } = {}) => {
    dispatch(Actions.widget.getSettings({ cb }))
  },
  pingWidget: ({ cb } = {}) => {
    dispatch(Actions.widget.ping({ cb }))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Acute))
