import React from 'react'
import ClassNames from 'classnames'
import BackBar from '../../components/BackBar'
import { Link } from 'react-router-dom'
import { FormGroup, InputGroup, TextArea, TagInput } from '@blueprintjs/core'
import { Emoji, Picker } from 'emoji-mart'

import { SubmitButton, CancelButton } from './style'
import './style.css'

const PICKER_STYLE = {
  width: '100%',
  position: 'absolute',
  left: '0',
  top: '100%',
  borderRadius: '0',
  marginTop: '-1px',
  zIndex: '1'
}

export default class NewCardView extends React.Component {
  state = {
    activePicker: 'none',
    title: '',
    body: '',
    tags: [],
    error: ''
  }

  constructor (props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.onSelectTitleEmoji = this.onSelectTitleEmoji.bind(this)
    this.onSelectBodyEmoji = this.onSelectBodyEmoji.bind(this)
  }

  onFieldChange (field, e) {
    const value = field === 'tags' ? e : e.target.value

    this.setState(() => ({ [field]: value }))
  }

  onSubmit () {
    const { title, body, tags } = this.state
    const { history, submitCard, showModal, ssoUser, token } = this.props

    const submitParams = {
      title,
      body,
      tags,
      cb: (err, res = {}) => {
        if (err) {
          const { response = {} } = err
          const { data = {} } = response

          this.setState({ error: data.error })
        } else {
          const { nonPublic } = this.props
          const { data = {} } = res
          const { id } = data

          if (id) {
            if (nonPublic) {
              this.setState({ successMessage: true })
            } else {
              history.push({
                pathname: `/card/${id}`,
                state: {
                  backRoute: '/'
                }
              })
            }
          }
        }
      }
    }

    const storage = localStorage.getItem('act_storage')
    const data = JSON.parse(storage) || {}
    const { user } = data

    if (token) {
      submitCard(submitParams)
    } else if (!token && user) {
      ssoUser({ 
        data: user,
        userAction: submitCard.bind(null, submitParams),
        cb: (err, res = {}) => {
        if (err) {
          const { response = {} } = err
          const { data = {} } = response

          console.log(`SSO failed with error ${data.error}.`)
          showModal(submitCard.bind(null, submitParams))
        }
      }})
    } else {
      showModal(submitCard.bind(null, submitParams))
    }
  }

  onSetActivePicker (picker) {
    this.setState(({ activePicker }) => ({
      activePicker: activePicker === picker ? 'none' : picker
    }))
  }

  closeEmojiDrawer = () => {
    this.setState({ activePicker: 'none' })
  }

  onSelectTitleEmoji (emoji = {}) {
    const { colons } = emoji

    this.setState(({ title }) => ({
      title: title + colons
    }))
  }

  onSelectBodyEmoji (emoji = {}) {
    const { colons } = emoji

    this.setState(({ body }) => ({
      body: body + colons
    }))
  }

  successMessage = () => {
    const { title, body, tags, activePicker, error, successMessage } = this.state
    const { history, darkColor, baseColor, modal, closeModal, organisation, nonPublic } = this.props

    const orgName = organisation.name || 'our service'

    return (
      <div className='aui-wrapper__new_card'>
        <BackBar
          history={history}
          darkColor={darkColor}
          baseColor={baseColor}
          modal={modal}
          closeModal={closeModal}
          nonPublic={nonPublic}
        />

        <div className='aui-wrapper__new_card-form'>
          {modal ?
            <>
              <h1 style={{ textAlign: 'center', fontSize: '1.2rem' }}>Thank you for your feedback!</h1>
              <p style={{ fontSize: '0.9rem' }} className='aui-wrapper__new_card-form__subtitle'>
                Thank you for sharing your feedback with us. Your feedback is highly valued and is used to make our service more useful for you.
              </p>
            </>
            : <>
              <p className='aui-wrapper__new_card-form__title'>Thank you for your feedback!</p>
              <p className='aui-wrapper__new_card-form__subtitle'>
                Thank you for sharing your feedback with us. Your feedback is highly valued and is used to make {orgName} more useful for you.
              </p>
            </>
          }
        </div>
      </div>
    )
  }

  render () {
    const { title, body, tags, activePicker, error, successMessage } = this.state
    const { history, darkColor, baseColor, modal, closeModal, organisation, nonPublic } = this.props

    const orgName = organisation.name || 'our service'

    if (successMessage) return this.successMessage()

    return (
      <div className='aui-wrapper__new_card'>
        <BackBar
          history={history}
          darkColor={darkColor}
          baseColor={baseColor}
          modal={modal}
          closeModal={closeModal}
          nonPublic={nonPublic}
        />

        <div className='aui-wrapper__new_card-form'>
          { modal ? 
            <>
              <h1 style={{textAlign: 'center', fontSize: '1.2rem'}}>Make a suggestion</h1>
              <p style={{ fontSize: '0.9rem' }} className='aui-wrapper__new_card-form__subtitle'>
                Let us know what features would make {orgName} more useful for you.
              </p>
            </>
          : <>
              <h1 style={{ textAlign: 'center', fontSize: '1.2rem' }}>Make a suggestion</h1>
              <p style={{ fontSize: '0.9rem' }} className='aui-wrapper__new_card-form__subtitle'>
                Let us know what features would make {orgName} more useful for you.
              </p>
            </>  
          }
          <div className="error__container">{ error }</div>

          <div className="input__container">
            <input
              type='text'
              placeholder='Short title'
              name='title'
              onChange={this.onFieldChange.bind(this, 'title')}
              onClick={this.closeEmojiDrawer}
              autocomplete="off"
            />
          </div>

          <div className="input__container">
            <textarea
              type='text'
              placeholder='Describe the feature'
              name='body'
              rows={window.innerHeight < 490 ? 5 : 7}
              onChange={this.onFieldChange.bind(this, 'body')}
              onClick={this.closeEmojiDrawer}
              autocomplete="off"
            />
          </div>

          <div className='aui-wrapper__new_card-actions'>
            { nonPublic ? null 
              : <CancelButton 
              className='aui-button aui-wrapper__new_card-actions__action' 
              to='/'
              darkColor={darkColor}>
                Cancel
              </CancelButton>
            }
            <SubmitButton
              className='aui-button aui-wrapper__new_card-actions__action'
              href='javascript:void(0)'
              onClick={this.onSubmit}
              darkColor={darkColor}
            >Submit</SubmitButton>
          </div>
        </div>
      </div>
    )
  }
}
