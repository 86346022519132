import React from 'react'
import ClassNames from 'classnames'
import { Icon, NonIdealState, Spinner } from '@blueprintjs/core'

import { ReactComponent as Trash } from '../../assets/trash.svg'
import EO from 'emojione'
import Autolink from '../../util/Autolink'

import Tags from '../../components/Tags'
import BackBar from '../../components/BackBar'
import WriteBox from '../../components/WriteBox'
import CommentList from '../../components/CommentList'
import timeDifference from '../../util/timeDifference'
import './style.css'
import { VoteButton } from '../../components/Card/style'

export default class CardDetailsView extends React.Component {
  constructor (props) {
    super(props)

    this.onComment = this.onComment.bind(this)
  }

  componentDidMount () {
    const { loadComments, loadAllVotes, card } = this.props

    if (card) {
      loadComments(card.id)
      loadAllVotes(card.id)
    }
  }

  componentWillReceiveProps (nextProps) {
    const { card, user, match, loadComments, loadAllVotes, getCard } = nextProps
    const { params = {} } = match

    if (!this.props.card) {
      getCard(params.id)
      return
    }

    if (card && card !== this.props.card) {
      loadComments(card.id)
      loadAllVotes(card.id)
    } else if (user !== this.props.user) {
      loadAllVotes(this.props.card.id)
    }
  }

  onComment ({ value, cb }) {
    const { card = {}, submitComment, showModal, ssoUser, token } = this.props

    const submitParams = {
      id: card.id,
      comment: value,
      cb
    }

    const storage = localStorage.getItem('act_storage')
    const data = JSON.parse(storage) || {}
    const { user } = data

    if (token) {
      submitComment(submitParams)
    } else if (!token && user) {
      ssoUser({
        data: user,
        userAction: submitComment.bind(null, submitParams),
        cb: (err, res = {}) => {
          if (err) {
            const { response = {} } = err
            const { data = {} } = response

            console.log(`SSO failed with error ${data.error}.`)
            showModal(submitComment.bind(null, submitParams))
          }
        }
      })
    } else {
      showModal(submitComment.bind(null, submitParams))
    }
    
  }

  upvote = () => {
    const { card = {}, submitUpvote, showModal, ssoUser, token } = this.props

    const storage = localStorage.getItem('act_storage')
    const data = JSON.parse(storage) || {}
    const { user } = data

    if (token) {
      submitUpvote(card.id)
    } else if (!token && user) {
      ssoUser({
        data: user,
        userAction: submitUpvote.bind(null, card.id),
        cb: (err, res = {}) => {
          if (err) {
            const { response = {} } = err
            const { data = {} } = response

            console.log(`SSO failed with error ${data.error}.`)
            showModal(submitUpvote.bind(null, card.id))
          }
        }
      })
    } else {
      showModal(submitUpvote.bind(null, card.id))
    }
  }

  downvote = () => {
    const { card = {}, submitDownvote, showModal, ssoUser, token  } = this.props

    const storage = localStorage.getItem('act_storage')
    const data = JSON.parse(storage) || {}
    const { user } = data

    if (token) {
      submitDownvote(card.id)
    } else if (!token && user) {
      ssoUser({
        data: user,
        userAction: submitDownvote.bind(null, card.id),
        cb: (err, res = {}) => {
          if (err) {
            const { response = {} } = err
            const { data = {} } = response

            console.log(`SSO failed with error ${data.error}.`)
            showModal(submitDownvote.bind(null, card.id))
          }
        }
      })
    } else {
      showModal(submitDownvote.bind(null, card.id))
    }
  }


  onVote = () => {
    const { votes = []} = this.props

    votes.has_voted ? this.downvote() : this.upvote()
  }

  onDelete = () => {
    const { card, deleteCard, history } = this.props

    deleteCard({ id: card.id, cb: (err, res = {}) => {
      if (err) {
        //TODO
      } else {
        history.push(`/`)
      }
    }})
  }

  render () {
    const { card, comments = [], votes = [], user = {}, history, 
      loading = false, darkColor, baseColor, modal, closeModal } = this.props

    if (loading) {
      return (
        <div className='aui-card-details__wrapper'>
          <div className='aui-card-details__header'>
            <BackBar
              history={history}
              darkColor={darkColor}
              baseColor={baseColor}
              modal={modal}
              closeModal={closeModal}
            />
          </div>

          <Spinner />
        </div>
      )
    }

    if (!card) {
      return (
        <div className='aui-card-details__wrapper'>
          <div className='aui-card-details__header'>
            <BackBar
              history={history}
              darkColor={darkColor}
              baseColor={baseColor}
              modal={modal}
              closeModal={closeModal}
            />
          </div>

          <NonIdealState
            title='Card not found'
          />
        </div>
      )
    }

    const { author = {} } = card

    return (
      <div className='aui-card-details__wrapper'>
        <div className='aui-card-details__header'>
          <BackBar
            history={history}
            darkColor={darkColor}
            baseColor={baseColor}
            modal={modal}
            closeModal={closeModal}
          />
        </div>

        <div className='aui-card-details__card'>
          <div className='aui-card-details__card_title'>
            <p dangerouslySetInnerHTML={{
              __html: EO.toImage(card.title)
            }}/>

            <VoteButton active={votes.has_voted}
              onClick={this.onVote}
              darkColor={darkColor}
            >
              <Icon icon='chevron-up' className="upvote"/>
              <span>{votes.votes || 0}</span>
            </VoteButton>

            { author.id === user.id ?
              <div className="aui-card-details__delete_button" onClick={this.onDelete}>
                <Trash width="16px" height="16px" alt=""/>
              </div>
            : null }
          </div>


          <p className='aui-card-details__card_body'>
            <Autolink text={card.body} />
          </p>
        </div>

        <div className='aui-card-details__card_author'>
          <img
            alt=''
            width={32}
            height={32}
            src={author.avatarURL}
          />
          {author.admin &&
            <Icon icon='endorsed' className="admin-badge" />
          }

          <div className='aui-card-details__card_tags'>
            <p
              className={ClassNames("aui-card-author", {
                admin: author.admin
              })}
            >
              {author.fullName}
            </p>
            <p>{timeDifference(new Date, new Date(card.createdAt))}</p>
          </div>
        </div>

        <div className='aui-card-details__comments'>
          <CommentList
            comments={comments}
          />
        </div>

        <WriteBox
          onSubmit={this.onComment}
          darkColor={darkColor}
        />
      </div>
    )
  }
}
