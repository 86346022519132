import React from 'react'
import { Icon } from '@blueprintjs/core'

import './style.css'

export default class SearchBar extends React.PureComponent {
  render () {
    const { value, onChange } = this.props

    return (
      <div className='aui-searchbar'>
        <input
          type='text'
          placeholder='Search...'
          value={value}
          onChange={onChange}
        />

        <Icon icon='search' />
      </div>
    )
  }
}
