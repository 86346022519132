import styled from 'styled-components'
import { transparentize } from 'polished'
import { Link } from 'react-router-dom'

export const SubmitButton = styled.div`
  display: block;

  background: ${props => props.darkColor ?
    props.darkColor : '#3023ae'};
  border-radius: 20px;

  color: #fff;
  font-weight: 600;

  padding: 6px 12px;
  margin: 0 5px;

  &:hover {
    cursor: pointer;
    color: #fff;
    ${props => props.darkColor ?
      `box-shadow: 0 0 0 2px ${transparentize(0.7, props.darkColor)}`
    : null}
  }
`

export const CancelButton = styled(Link)`
  display: block;

  background: #fff;
  border-radius: 20px;

  color: #fff;
  font-weight: 600;

  padding: 6px 12px;
  margin: 0 5px;

  border: 1px solid ${props => props.darkColor ?
    props.darkColor : '#3023ae'};
  color: ${props => props.darkColor ?
    props.darkColor : '#3023ae'};

  &:hover {
    cursor: pointer;
    color: ${props => props.darkColor ?
    props.darkColor : '#3023ae'};
    ${props => props.darkColor ?
      `box-shadow: 0 0 0 2px ${transparentize(0.7, props.darkColor)}`
    : null}
  }
`