import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { actions as Actions } from 'acute-uilib'
import Card from './Card'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data = {} } = state
  const { user = {}, token = null } = data

  return {
    user,
    token
  }
}

const mapDispatchToProps = dispatch => ({
  submitUpvote: (cardId) => {
    dispatch(Actions.cardVotes.submitUpvote(cardId))
  },

  submitDownvote: (cardId) => {
    dispatch(Actions.cardVotes.submitDownvote(cardId))
  },

  loadAllVotes: (cardId) => {
    dispatch(Actions.cardVotes.loadAll(cardId))
  },

  showModal: (userAction) => {
    dispatch(Actions.modal.show(userAction))
  },

  ssoUser: ({ data, userAction, cb } = {}) => {
    dispatch(Actions.userAction.set(userAction))
    dispatch(Actions.user.sso({ data, cb }))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Card))
