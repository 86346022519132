import React from 'react'
import { AutoSizer, List, CellMeasurerCache } from 'react-virtualized'
import Scrollbars from 'react-custom-scrollbars'
import { Spinner } from '@blueprintjs/core'

import { renderRow } from './helpers'
import { ScrollbarOffset } from './constants'
import './style.css'

export default class CardList extends React.PureComponent {
  constructor (props) {
    super(props)

    this.cache = new CellMeasurerCache({
      defaultHeight: 280,
      fixedWidth: true,
      keyMapper: (index) => {
        const item = this.props.cards[index] || {}
        return item ? `${item.id}` : index
      },
    })
  }

  componentWillReceiveProps (nextProps) {
    const { cards = [] } = nextProps

    if (cards.length !== this.props.cards.length && this.list) {
      this.list.recomputeRowHeights(0)
    }
  }

  render () {
    const { emptyState, cards = [], votes = [], loading = false, darkColor } = this.props

    if (loading) {
      return (
        <div className='aui-card-list'>
          <Spinner />
        </div>
      )
    }

    if (cards.length === 0 && emptyState) {
      return (
        <div className='aui-card-list'>
          { emptyState }
        </div>
      )
    }

    return (
      <div className='aui-card-list'>
        <AutoSizer style={{ outline: 'none' }}>
          {({ height, width }) => (
            <Scrollbars
              autoHide
              style={{ height, width: width + ScrollbarOffset }}
            >
              <List
                deferredMeasurementCache={this.cache}
                rowRenderer={renderRow.bind(this, this.cache, cards, votes, darkColor)}
                width={width}
                height={height}
                rowHeight={this.cache.rowHeight}
                rowCount={cards.length}
                ref={(list) => this.list = list}
              />
            </Scrollbars>
          )}
        </AutoSizer>
      </div>
    )
  }
}
