import React from 'react'

const renderFormFields = (dynamicFields, _handleChange = null) => {
  return (
    dynamicFields.map(field => {
      const { type, placeholder, key, name } = field

      return(
        <div className="input__container" key={key}>
          <input
            type={type}
            placeholder={placeholder}
            key={key}
            name={name}
            onChange={_handleChange}
          />
        </div>
      )
    })
  )
}

export { renderFormFields }
