import React from 'react'

import './style.css'

require('dotenv').config()

const MAIN_WEBSITE_URL = process.env.REACT_APP_MAIN_WEBSITE_URL;

export default class Footer extends React.PureComponent {
  render () {
    const { whiteLabel = false, orgName } = this.props

    return (
      whiteLabel === false ?
        <div className='aui__footer-container'>
          <a 
            href={`${MAIN_WEBSITE_URL}/acute-link?utm_source=widget&utm_medium=powered&utm_campaig=${orgName}&company=${orgName}`} 
            target='_blank'>
            <span className='message' style={{fontSize: '12px'}}>Powered by </span>
            <img className='logo' src='/images/acute.png' alt='Acute Logo' />
          </a>
        </div>
      : null
    )
  }
}
