import { connect } from 'react-redux'
import { actions as Actions } from 'acute-uilib'

import CardDetailsView from './CardDetails'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { match = {} } = ownProps
  const { params = {} } = match
  const { id } = params

  const { data = {} } = state
  const { cards = {}, comments = {}, votes = {}, user = {}, loading = {}, token = null } = data
  const card = cards[id]
  const cardComments = comments[id]
  const cardVotes = votes[id]

  return {
    user,
    token,
    card,
    comments: cardComments,
    votes: cardVotes,
    loading: loading.isFetching
  }
}

const mapDispatchToProps = dispatch => ({
  loadComments: (cardId) => {
    dispatch(Actions.comments.loadForCard(cardId))
  },

  loadAllVotes: (cardId) => {
    dispatch(Actions.cardVotes.loadAll(cardId));
  },

  submitUpvote: (cardId) => {
    dispatch(Actions.cardVotes.submitUpvote(cardId))
  },

  submitDownvote: (cardId) => {
    dispatch(Actions.cardVotes.submitDownvote(cardId))
  },

  submitComment: ({ id, comment, cb } = {}) => {
    dispatch(Actions.comments.submit({ id, comment, cb }))
  },

  deleteCard: ({ id, cb } = {}) => {
    dispatch(Actions.card.remove({ id, cb }))
  },

  getCard: (cardId) => {
    dispatch(Actions.card.getById(cardId))
  },

  showModal: (userAction) => {
    dispatch(Actions.modal.show(userAction))
  },

  ssoUser: ({ data, userAction, cb } = {}) => {
    dispatch(Actions.userAction.set(userAction))
    dispatch(Actions.user.sso({ data, cb }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(CardDetailsView)
